import React, {useContext} from "react";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import ZeeMenus from "./menus";
import siteContext from "./siteContext";

export default function Footer({menus}) {
  const siteSettings = useContext(siteContext);
  return (
    <footer className="footer">
      <div className="container">
        <div className="scrollToTop">
          <div className="line"></div>
          <div
            id="scrollicon"
            className="go-to-top scrollToTop__icon justify-content-center"
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <HiOutlineArrowNarrowUp />
          </div>
        </div>
        <div class="row clearfix">
          <div class="footlft col-xs-12 col-sm-12 col-md-12">
            <div class="app-list clearfix">
              <ZeeMenus menu={menus.download} title="Download Apps"/>
            </div>
            <div class="foot-nav py-3 clearfix">
              <ZeeMenus menu={menus.footer}/>
            </div>
            <div class="copy-right clearfix">
              <p>{siteSettings.copyRights}</p>
            </div>
          </div>
          <div class="footrgt col-xs-12 col-sm-12 col-md-4">
            <div class="social-icons">
              <ZeeMenus menu={menus.sociallinks}/>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
