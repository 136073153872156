import React from "react";
import { useEffect, useState, useRef } from "react";
import Layout from '../components/staticLayout';
import MetaDetails from "../components/meta";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FEEDBACK_FORM_URL, SITEID, SITE_CHANNEL,SITE_CHANNEL1, FEEDBACK_FORM_ID, SITE_CAPTCHA_KEY, STATES_URL, SITENAME} from "../util/constants";

const Feedback = () => {
    const [formData1, setFormData] = useState({gender:'Male'});
    const [birthday, setBirthDay] = useState(null);
    const [formStatus, setFormStatus] = useState(false);
    const [, setcaptcha] = useState('');
    const recaptchaRef = useRef();
    
    useEffect(()=>{
        setFormData(prevState =>{ return { ...prevState , 'birthday' : birthday }});
    },[birthday])

    const updateFormData = (e) =>{
        let elem = e.target.name;
        let val = e.target.value;
        setFormData(prevState =>{ return { ...prevState , [elem] : val }});
        if(elem=='country' && val!==''){
            countryChange(val);
        }
        validateField(e);
    }

    const logSubmit= (e) => {
        e.preventDefault();
        let formValid = true; 
        
        if(document.querySelectorAll('.error').length>0){
            formValid = false; 
        }
        const formData = {...formData1};
        formData['gender'] = document.querySelector('input[name="gender"]:checked').value;
        formData['site_id'] = SITEID; 

        formData["g-recaptcha-response"] = recaptchaRef.current.getValue();
        if(formData["g-recaptcha-response"] ==='' ){
            formValid = false;
            document.getElementById('captcha_error').innerHTML = 'Please select captcha'; 
        } else {
            document.getElementById('captcha_error').innerHTML = '';
        }
        
        if(birthday==null || birthday ==''){
            formValid = false;
            document.querySelector('.birthDate').classList.add('error');
        }
        else{
            document.querySelector('.birthDate').classList.remove('error')
        }
            

        document.querySelectorAll('[aria-required="true"]').forEach(ele => { 
            if(ele.value === '') {
                ele.classList.add('error');
                formValid=false;
            }
            formData[ele.name] = ele.value;
        })
   
        if(formValid){
            let datevalue = formData['birthday'];
            let d = new Date(datevalue);
            let dt = d.getDate();
            let mn = d.getMonth();
            mn++;
            let yy = d.getFullYear();
            formData['birthday'] = yy + "-" + mn + "-" +dt;
            $.post( FEEDBACK_FORM_URL, formData)
            .done(
                function(data){
                    let statusFld = document.getElementById('formStatus')
                    if(data['status']=='success'){
                        statusFld.innerHTML="Thanks for your feedback";
                        statusFld.classList.add('success-text');
                        statusFld.classList.remove('error-text');
                        setFormData({gender:'Male'});
                        document.querySelectorAll('[aria-required="true"]').forEach(ele => { 
                            if(ele.type!='hidden') {
                                ele.value = ''
                                formData[ele.name] = '';
                            }
                        })
                        document.querySelector('.birthDate').value='';
                    } else {
                        document.getElementById('formStatus').innerHTML= data['error'] || "Error in submitting the form"
                        statusFld.classList.add('error-text');
                        statusFld.classList.remove('success-text');
                    }
                    
                        
                }
            )       
        }
        
    }

    const validateField = (e) => {
        let ele = e.target;
        validations(ele)
    }

    const validations = (ele) => {
        let hasError = false; 
        if(ele.value==''){
            hasError = true; 
        } else if(ele.type==='email'){
            let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!ele.value.match(mailformat)) {
                hasError = true; 
            }
        } else if(ele.type==='tel'){
            let phoneno = /^\d{10}$/;
            if (!ele.value.match(phoneno)) {
                hasError = true; 
            }
        }

        if(hasError) {
            ele.classList.add('error');
        } else {
            ele.classList.remove('error');
        }
    }

    const countryChange = value => {
        var result = value;
        $.ajax({
          type: "get",
          url: `${STATES_URL}${result}`,
          success: function (data) {
            $("#state").html(data);
          },
        });
    }

  return (
    <Layout >
        <h4 >Feedback</h4>
        <div role="form" className="wpcf7" id="wpcf7-f29-p30-o1" lang="en-US" dir="ltr">
            <div className="form-step form-width-70" >
                <div className="form-step form-width-70" >
                <div role="form" className="wpcf7" id="wpcf7-f29-o1" lang="en-US" dir="ltr">
    <div className="screen-reader-response"></div>
        <form action="" method="post" id="wpcf7-form" className="wpcf7-form" novalidate="novalidate" onSubmit={logSubmit}>
            {SITEID==4 &&
            <div style={{display: 'none' }}>
                <input type="hidden" name="form_post_id" aria-required="true" value={FEEDBACK_FORM_ID} />
                <input type="hidden" name="channel_name" aria-required="true" value={SITE_CHANNEL} className="" />
                <input type="hidden" name="channel_name1" aria-required="true" value={SITE_CHANNEL1} className="" />
            </div>
            }
            {SITEID==5 &&
            <div style={{display: 'none' }}>
                <input type="hidden" name="form_post_id" aria-required="true" value={FEEDBACK_FORM_ID} />
                <input type="hidden" name="_wpcf7_container_post" value="50043" />
                <input type="hidden" name="channel_name" aria-required="true" value={SITE_CHANNEL} className="" />
            </div>
            }
           
            <p>Name *<br />
                <span className="full_name">
                    <input type="text" name="full_name" value={formData1.full_name} size="40" className="wpcf7-text" aria-required="true" onChange={updateFormData} />
                </span>
            </p>
            <p>Email ID *<br />
                <span className="emailid">
                    <input type="email" name="emailid" value={formData1.emailid} size="40" className="wpcf7-email" aria-required="true"  onChange={updateFormData} />
                </span>
            </p>
            <p>Contact No *<br /> 
                <span className="ContactNo">
                    <input type="tel" name="ContactNo" value={formData1.ContactNo} size="40" className="wpcf7-tel" aria-required="true"  onChange={updateFormData} />
                </span> 
            </p>
            <p>Date of Birth *<br />
                <span className="birthday">
                    <DatePicker selected={birthday} onChange={(date) => setBirthDay(date)} className="birthDate" maxDate={new Date()}/>
                </span>
            </p> 
        <p>Gender
            *<br />
            <span className="gender">
                <span className="wpcf7-radio">
                    <span className="wpcf7-list-item first">
                        <input type="radio" name="gender" value="Male" checked={(formData1.gender && formData1.gender=='Male')} onClick={updateFormData}/>
                        <span className="wpcf7-list-item-label">Male</span>
                    </span>
                    <span className="wpcf7-list-item">
                        <input type="radio" name="gender" value="Female" checked={(formData1.gender && formData1.gender=='Female')} onClick={updateFormData}/>
                        <span className="wpcf7-list-item-label">Female</span>
                    </span>
                    <span className="wpcf7-list-item last">
                        <input type="radio" name="gender" value="Other" checked={(formData1.gender && formData1.gender=='Other')} onClick={updateFormData}/>
                        <span className="wpcf7-list-item-label">Other</span>
                    </span>
                </span>
            </span>
        </p>
        <p>Area/Location *
            <br />
            <span className="Area_Location">
                <input type="text" name="Area_Location" value={formData1.Area_Location} size="40" className="wpcf7-text" aria-required="true" onChange={updateFormData} />
            </span>
        </p>
        <p>City / Town *
            <br /> 
            <span className="City">
                <input type="text" name="City" value={formData1.City} size="40" className="" aria-required="true" onChange={updateFormData}  />
            </span>
        </p>
        <p>Country *
            <br /> 
            <span className="country">
                <select name="country" value={formData1.country} className="" id="country" aria-required="true" onChange={updateFormData}  >
                    <option value="">---</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Albania">Albania</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Beni">Beni</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belize">Belize</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Canada">Canada</option>
                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="CÃ´te dâ€™Ivoire">CÃ´te dâ€™Ivoire</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Germany">Germany</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Algeria">Algeria</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Spain">Spain</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Finland">Finland</option>
                    <option value="Fiji Islands">Fiji Islands</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="France">France</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Gabon">Gabon</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Greece">Greece</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="Guam">Guam</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Japan">Japan</option>
                    <option value="Kazakstan">Kazakstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="South Korea">South Korea</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Laos">Laos</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Macao">Macao</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Namibia">Namibia</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="Niger">Niger</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niue">Niue</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Norway">Norway</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Nauru">Nauru</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Panama">Panama</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Palau">Palau</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Poland">Poland</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="North Korea">North Korea</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Palestine">Palestine</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="Qatar">Qatar</option>
                    <option value="RÃ©union">RÃ©union</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Somalia">Somalia</option>
                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Syria">Syria</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Chad">Chad</option>
                    <option value="Togo">Togo</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="United States">United States</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Yugoslavia">Yugoslavia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                </select>
            </span>
        </p>
        <p>State *<br />
            <span className="state">
                <select name="state" className="" id="state" aria-required="true" value={formData1.state} onChange={updateFormData} >
                    <option value="">---</option>
                </select>
            </span>
        </p>
        <p>Platform ( Where do you watch our content) *<br /> <span className="platform">
            <select name="platform" className="" id="platform" aria-required="true" value={formData1.platform} onChange={updateFormData} >
                <option value="TV">TV</option>
                <option value="Ditto TV">Ditto TV</option>
                <option value="ZEE5 Website">ZEE5 Website</option>
                <option value="ZEE5 App">ZEE5 App</option>
            </select></span></p>
        <p>Mention your Cable/DTH operator *<br /> 
            <span className=" cable_dth_operator">
                <input type="text" name="cable_dth_operator" size="40"
                    className="" aria-required="true" value={formData1.cable_dth_operator} onChange={updateFormData}
                     />
            </span> 
        </p>
        <p>Feedback *<br /> 
            <span className="feedback">
                <textarea name="feedback" cols="40" rows="10" className="wpcf7-textarea
                        " aria-required="true" value={formData1.feedback} onChange={updateFormData}></textarea>
            </span>
        </p>
        <p>
             <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={SITE_CAPTCHA_KEY}
                onChange={(e) => {
                setcaptcha(e);
                document.getElementById('captcha_error').innerHTML='';
                }}
            />
            <span id='captcha_error' class='error-text'></span>
        </p>
        <p>
            <input type="submit" value="Send" className="wpcf7-submit" /></p>
        <div id='formStatus' className="success-text wpcf7-display-none"></div>
    </form>
</div>
                    
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default Feedback
export const Head = () => (<MetaDetails meta={{
    "title": `Feedback - ${SITENAME}`,
    "description": `${SITENAME} Feedback description`,
  }} />)