import React, { useState, useEffect, useContext } from 'react';
import ZeeMenus from "./menus";
import siteContext from "./siteContext";

const Header = ({menus,logopath}) => {

  useEffect(() => {
    window.addEventListener('scroll', changeColor)
    return ()=> {window.removeEventListener('scroll',changeColor);}
  },[]);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 86) {
      setColor(true)
    } else {
      setColor(false)
    }
  }
  const siteSettings = useContext(siteContext);

  function openNav() {
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("main").style.marginLeft = "100%";
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  return (
    <header>
      <div className={color ? 'header header-bg' : 'header'}>
        <div className='row'>
          <div className='col-12'>
            <nav className="header navbar navbar-expand-lg">
              <div className="container">
                <div id="mySidebar" className="sidebar">
                  <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>×</a>
                  {menus.header.map(menuItem => <a href={menuItem.link}>{menuItem.label}</a>)}
                </div>
                <div id="main">
                  <button class="openbtn" onClick={openNav}><img src="/images/hamburger.png" alt="tvicon" width="26" /></button>
                </div>
                <a className="navbar-brand" href="/">
                  <img src={logopath} alt="logo" width="52" />
                </a>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                 <ZeeMenus menu={menus.header} menuClass="navbar-nav mr-auto" menuItemClass="nav-item"/>
                </div>
                <div className="header__right">
                  <a
                    href="https://www.zee5.com/tvguide"
                    className="nav__link  header__tv--link"
                  >
                    TV GUIDE
                  </a>

                  <a
                    href="https://www.zee5.com/tvguide"
                    className="nav__link header__tv--icon"                  >
                    <img src="/images/tvicon.png" alt="tvicon" width="26" />
                  </a>

                </div>
              </div>
            </nav>
          </div>
        </div>



      </div>

    </header>

  );
};

export default Header;
