import React from 'react'
import { Link } from "gatsby";

function ZeeMenus({menu, title, menuClass, menuItemClass}) {
  return (
    <ul className={menuClass}>
      {title && ( <li className={menuItemClass}><h3>{title}</h3></li> )}
      { menu.map(menuitem => <MenuItem menuitem={menuitem} menuItemClass={menuItemClass}/>)}
    </ul>
  );
}
export default ZeeMenus

function MenuItem({menuitem, menuItemClass}) {
  return (
    <li className={menuItemClass}>
          <Link to={menuitem.link}>
            <span className={menuitem.class}>
            {menuitem.image ? (
              <img src={menuitem.image} width="96" alt={menuitem.label} />
            ) : (
              menuitem.label
            )}
            </span>
          </Link>
    </li>
    )
}
